import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles= makeStyles((theme) =>({
  logo:{
    "& img":{
      width:"125px !important"
    }
  }
}))

const Logo = (props) => {
  const classes = useStyles()
  return(
    <Box className={classes.logo}>
      <img src="/images/logo.png" alt="Logo" {...props} />
    </Box>
  )
 

  ;
};

export default Logo;
